import Link from "next/link";
import { NavigationLinks } from "./footer.types";
import { Logo } from "../shared/Logo";
import { Routes } from "src/app/_constants/routes";

const NAVIGATION_LINKS: NavigationLinks = [
  {
    title: "For Companies",
    links: [
      { name: "Sign Up", href: Routes.auth.signupEmployer },
      { name: "Post a Job", href: Routes.landing.forEmployers },
      {
        name: "Book a Demo",
        href: Routes.landing.schedule.demo,
        external: true,
      },
      { name: "Learn More", href: Routes.landing.findTalent },
      {
        name: "Employer Support",
        href: Routes.landing.resources.forCompanies.employerSupport,
        external: true,
      },
    ],
  },
  {
    title: "Job Seekers",
    links: [
      { name: "Sign Up", href: Routes.auth.signupJobSeeker },
      { name: "Search Jobs", href: Routes.landing.findJobs },
      { name: "Learn More", href: Routes.landing.findJobs },
    ],
  },
  {
    title: "Help",
    links: [
      {
        name: "Contact Us",
        href: Routes.landing.resources.help.contactUs,
        external: true,
      },
      {
        name: "Support",
        href: Routes.landing.resources.help.support,
        external: true,
      },
    ],
  },
] as const;

const LEGAL_LINKS = [
  { name: "Terms of Service", href: Routes.landing.legal.termsOfService },
  { name: "Privacy Policy", href: Routes.landing.legal.privacyPolicy },
  { name: "DPA", href: Routes.landing.legal.dpa },
] as const;

const SOCIAL_LINKS = [
  {
    name: "X (Twitter)",
    href: Routes.landing.social.xTwitter,
    icon: (
      <svg
        className="h-5 w-5"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
      </svg>
    ),
    external: true,
  },
  {
    name: "LinkedIn",
    href: Routes.landing.social.linkedin,
    icon: (
      <svg
        className="h-5 w-5"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z" />
      </svg>
    ),
    external: true,
  },
] as const;

export const DesktopNewsletterForm = () => (
  <div
    className="mt-6 hidden max-w-[435px] lg:block"
    data-testid="newsletter-desktop-form"
  >
    <iframe
      src="https://embeds.beehiiv.com/633e6380-da84-46dc-ae7a-3e7959410436?slim=true"
      data-test-id="beehiiv-embed"
      height="52"
      className="w-full"
      style={{ margin: 0, borderRadius: 0, backgroundColor: "transparent" }}
    />
  </div>
);

export const MobileNewsletterForm = () => (
  <div className="block lg:hidden" data-testid="newsletter-mobile-form">
    <h3 className="text-2xl font-medium">
      Join our newsletter to keep up to date with us!
    </h3>
    <p className="mt-2 text-base text-neutral-secondary">
      Stay informed with the latest hiring trends, product updates and
      recruitment insights.
    </p>
    <div className="mt-6">
      <iframe
        src="https://embeds.beehiiv.com/633e6380-da84-46dc-ae7a-3e7959410436?slim=true"
        data-test-id="beehiiv-embed"
        height="52"
        className="w-full"
        style={{ margin: 0, borderRadius: 0, backgroundColor: "transparent" }}
      />
    </div>
  </div>
);

const MainContent = () => (
  <div className="mx-auto flex h-auto max-w-screen-xl items-center justify-center px-4 py-11 lg:py-16">
    <div className="grid w-full">
      <div className="flex min-h-full flex-col justify-center gap-8 lg:flex-row lg:justify-between">
        <div className="flex w-full flex-col lg:w-1/2">
          <MobileNewsletterForm />
          <div className="my-8 block h-full border-t border-neutral-10 lg:hidden" />
          <Logo />
          <div className="mt-6 text-base text-neutral-secondary lg:max-w-[437px]">
            Democratizing Hiring in the Era of AI with the world's first
            integrated Applicant Tracking System and Job Board.
          </div>
          <DesktopNewsletterForm />
        </div>
        <nav className="grid w-full grid-cols-1 gap-8 lg:w-1/2 lg:grid-cols-3">
          {NAVIGATION_LINKS.map(section => (
            <div key={section.title}>
              <h3 className="mb-6 whitespace-nowrap text-base font-medium">
                {section.title}
              </h3>
              <ul className="font-regular whitespace-nowrap text-neutral-secondary">
                {section.links.map((link, index) => (
                  <li key={index} className="mb-4 last:mb-0">
                    {link.external ? (
                      <a
                        href={link.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.name}
                      </a>
                    ) : (
                      <Link href={link.href}>{link.name}</Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </nav>
      </div>
    </div>
  </div>
);

const BottomBar = () => (
  <div className="w-full bg-brand-primary-dark-00">
    <div className="mx-auto w-full max-w-screen-xl px-4 py-6">
      <div className="flex flex-col gap-4 lg:flex-row lg:items-center lg:justify-between">
        <div className="flex flex-col items-start sm:flex-row lg:m-0 lg:flex-col lg:items-center">
          <span className="mt-1 text-left text-sm text-white lg:text-center">
            Copyright © {new Date().getFullYear()} SCALIS. All Rights Reserved.
          </span>
        </div>
        <div className="mt-1 flex flex-row items-center justify-between lg:justify-center lg:gap-16">
          <div className="flex justify-start gap-4 lg:justify-center">
            {LEGAL_LINKS.map(link => (
              <Link
                key={link.href}
                href={link.href}
                className="text-left text-sm text-white hover:text-gray-200 lg:text-center"
              >
                {link.name}
              </Link>
            ))}
          </div>
          <div className="flex justify-end gap-4 lg:justify-center">
            {SOCIAL_LINKS.map(link => (
              <Link
                key={link.href}
                href={link.href}
                className="text-sm text-white hover:text-gray-200"
                target="_blank"
                rel="noopener noreferrer"
                aria-label={link.name}
              >
                {link.icon}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const Footer = () => (
  <footer className="bg-brand-primary-light-10">
    <MainContent />
    <BottomBar />
  </footer>
);
