"use client";

import { Footer } from "~/scalis-components/landing/components/footer/footer";
import { Navbar } from "~/scalis-components/landing/components/navbar/navbar";
import "~/styles/static.css";

export default function LandingLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </>
  );
}
