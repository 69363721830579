"use client";
import { useState, useEffect } from "react";
import { Logo } from "../shared/Logo";
import { Button } from "@scalis/components/core/button";
import { NAV_BTN_CONFIG } from "./navbar.constants";
import { MobileNav } from "./mobile-nav";
import Link from "next/link";
import { LoginButton } from "../shared/LoginButton";
import { AccountDropdownMenu } from "~/scalis-components/core/navbar/account-dropdown-menu/account-dropdown-menu";
import { usePathname } from "next/navigation";
import { useSession } from "next-auth/react";
import { Routes } from "~/src/app/_constants/routes";

interface NavLinkProps {
  href: string;
  label: string;
}

const NavLink = ({ href, label }: NavLinkProps) => {
  const pathname = usePathname();
  const isActive = pathname === href;

  return (
    <Link
      href={href}
      aria-label={label}
      className={`whitespace-nowrap rounded-xl px-3 py-2 text-base font-medium leading-6 transition-colors 
        ${
          isActive
            ? "border-brand-primary-rest bg-brand-primary-light-10 text-brand-primary-rest hover:bg-brand-primary-light-10"
            : "text-neutral-primary hover:bg-neutral-10"
        }`}
    >
      {label}
    </Link>
  );
};

const NavigationLinks = () => (
  <div className="ml-8 hidden space-x-2 lg:block">
    <NavLink href={Routes.landing.forEmployers} label="For Employers" />
    {/* <NavLink href="/for-job-seekers" label="For Job Seekers" /> */}
  </div>
);

const useScroll = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return scrolled;
};

const AuthenticatedActions = () => {
  const { data: session } = useSession();
  const user = session?.user;

  return (
    <>
      <Button
        {...NAV_BTN_CONFIG.searchJob}
        className="hidden whitespace-nowrap border-0 text-base text-brand-primary-rest lg:flex"
      />
      <div className="mr-2 hidden lg:flex">
        <AccountDropdownMenu
          email={user?.email ?? ""}
          imageSrc={user?.image ?? ""}
          name={user?.name ?? ""}
        />
      </div>
    </>
  );
};

const GuestActions = () => (
  <>
    <Button
      {...NAV_BTN_CONFIG.searchJob}
      className="hidden whitespace-nowrap border-0 text-base text-brand-primary-rest lg:flex"
    />
    <LoginButton className="hidden lg:flex" />
  </>
);

export const Navbar = () => {
  const scrolled = useScroll();
  const { status, data: session } = useSession();
  const user = session?.user;
  const isAuthenticated = status === "authenticated" && user?.emailVerified;

  return (
    <header className="fixed z-50">
      <nav
        className={`fixed left-4 right-4 mx-auto h-[72px] max-w-7xl rounded-2xl bg-neutral-00 px-4 shadow-lg transition-all duration-300 ${
          scrolled ? "top-3" : "top-5 lg:top-8"
        }`}
      >
        <div className="flex h-full items-center">
          <div className="flex items-center gap-4">
            <MobileNav />
            <Logo />
          </div>
          <NavigationLinks />
          <div className="ml-auto flex items-center gap-2">
            {isAuthenticated ? <AuthenticatedActions /> : <GuestActions />}
            <Button {...NAV_BTN_CONFIG.scheduleDemo}>
              <span className="whitespace-nowrap text-sm lg:text-base">
                Schedule a demo
              </span>
            </Button>
          </div>
        </div>
      </nav>
    </header>
  );
};
